import React, { Component } from 'react';
import logo from './logo.svg';
import tacocat from './tacocat.png';
import './App.css';

class App extends Component {
  render() {
    return (
      <div className="App">
        <header className="App-header">
          <img src={logo} className="App-logo" alt="logo" />
          <h1>Hey!</h1>
          <p>
            Here's a picture of my favorite palindrome.
          </p>
          <img src={tacocat} className="taco-cat" alt="tacocat" />
          <p>Looking for chocolate zucchini cake?</p>
        </header>
      </div>
    );
  }
}

export default App;
